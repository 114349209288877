// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-products-list-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/templates/ProductsList.js" /* webpackChunkName: "component---src-templates-products-list-js" */),
  "component---src-templates-product-page-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/templates/ProductPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-category-page-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/templates/CategoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-collection-page-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/templates/CollectionPage.js" /* webpackChunkName: "component---src-templates-collection-page-js" */),
  "component---src-pages-404-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-addresses-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/pages/addresses.js" /* webpackChunkName: "component---src-pages-addresses-js" */),
  "component---src-pages-cart-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-categories-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-checkout-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-collections-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-configurator-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/pages/configurator.js" /* webpackChunkName: "component---src-pages-configurator-js" */),
  "component---src-pages-index-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-orderdetails-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/pages/orderdetails.js" /* webpackChunkName: "component---src-pages-orderdetails-js" */),
  "component---src-pages-orders-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/pages/orders.js" /* webpackChunkName: "component---src-pages-orders-js" */),
  "component---src-pages-profile-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-search-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-signup-js": () => import("/var/lib/jenkins/workspace/DemoOps/DeployGatsbyStoreS3/DeployGatsbyStoreS3/src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */)
}

