import React from 'react'

export default function Banner() {
  return (
    <div className="bottom-banner my-6 p-6 text-center">
      <p className="bottom-banner-text">
        The Vestri Difference. Pioneering new technology. Breaking industry norms.
      </p>
    </div>
  )
}
